import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Box from "../../components/modules/Box/box.js";
import Seo from "../../components/seo/seo.js";
import {Title} from "../../components/elements/Title/title";
import {Breadcrumbs} from "../../components/modules/Breadcrumbs/breadcrumbs";
import RichText from "../../components/modules/RichText/richText";
import LeafletMap from "../../components/modules/Map/leafletMap";
import Faq from "../../components/modules/Faq/faq";
import List from "../../components/modules/List/list.js";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";
import Jumbotron from "../../components/modules/Jumbotron/jumbotron.js";
import Tag from "../../components/elements/Tag/tag.js";
import {Opinions} from "../../components/modules/Opinions/opinions";

class Type extends Component {

    render() {
        const seo = this.props.pageContext.seo;
        const textStatic = this.props.pageContext.textStatic;

        const breadcrumbs = this.props.pageContext.data.breadcrumbs;
        const mainTitle = seo.mainTitle || false;
        const mainText = this.props.pageContext.mainText || false;
        const subTitle = seo.subTitle || false;
        const tagText = this.props.pageContext.tagText || false;
        const subTitleMap = seo.subTitleMap || false;
        const subTitleList = seo.subTitleList || false;
        const listTable = this.props.pageContext.listTable;
        const listTableCol = this.props.pageContext.listTableCol || 1;
        const dataMap = this.props.pageContext.dataMap || false;
        const asks = this.props.pageContext.asks || false;
        const image_bg = this.props.pageContext.image_bg;
        const country_code = seo.country_code;
        const type_id = this.props.pageContext.type_id;
        const region_1_slug = this.props.pageContext.data.region_1_slug || false;
        const region_2_slug = this.props.pageContext.data.region_2_slug || false;
        const region_3_slug = this.props.pageContext.data.region_3_slug || false;

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description}  robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module withoutPad bg>
                    <Wrapper withoutPad>
                        <Column adapt>
                            <Breadcrumbs content={breadcrumbs} host={seo.host}/>
                            <JsonBreadcrumbs data={breadcrumbs} host={seo.host}/>
                        </Column>
                    </Wrapper>
                </Module>

                <Jumbotron 
                    bg={image_bg}
                    title={mainTitle}
                />

                <Module>
                    <Wrapper middle>
                        <Column row>
                            <Column>

                                {tagText && (<Tag text={tagText} />)}

                                <Box border>
                                    {subTitle && (
                                        <Title tag={`h2`} size20 content={subTitle} />
                                    )}
                                    {mainText && (
                                        <RichText content={mainText} />
                                    )}
                                    {subTitleMap && (
                                        <>
                                        <Title tag={`h2`} size20 content={subTitleMap} />
                                        <LeafletMap dataMap={dataMap} textStatic={textStatic} zoom={15}/>
                                        </>
                                    )}
                                    {subTitleList && (
                                        <Title tag={`h2`} size20 content={subTitleList} />
                                    )}
                                    <List col={listTableCol} colSm={1} data={listTable} />
                                </Box>
                                {asks && (
                                    <Faq asks={asks} title={textStatic.faq_title}/>
                                )}

                                <Title size20 tag="h2" content={textStatic.opinions_title} className="text-align--center marg-t60" />

                                <Opinions
                                    textStatic={textStatic}
                                    seo={seo}
                                    country_code={country_code}
                                    count={10}
                                    typePage={"type"}
                                    type_id={type_id}
                                    region_1_slug={region_1_slug}
                                    region_2_slug={region_2_slug}
                                    region_3_slug={region_3_slug}
                                />
                            </Column>
                        </Column>
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default Type;